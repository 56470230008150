import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { LobListService } from './lob-list.service';
import { EntitlementService } from '../../shared/service/entitlement.service';

@Component({
  selector: 'app-lob-list',
  templateUrl: './lob-list.component.html',
  styleUrls: ['./lob-list.component.css']
})
export class LobListComponent implements OnInit {

  errorMessage: string;
  lobsLoaded: boolean;
  isEligible: boolean;
  appConfig: any;
  marketlandscapeUrl: string = '';
  products: any;
  s3FileDetails: any;
  isoticket: string;
  loads3fileDetails: boolean = false;
  progress = 0;

  constructor(private appService: AppService,
    private cookieService: CookieService, private loblistService: LobListService,
    private router: Router, private entitlementService: EntitlementService) {

  }

  ngOnInit(): void {
    this.appService.getConfigStatus().subscribe((configStatus: boolean) => {
      if (configStatus) {
        this.appConfig = this.appService.getGlobalConfig();

        this.isoticket = this.cookieService.get('ticket');

        this.entitlementService.loadEligibility();

        this.entitlementService.eligibility$.subscribe((data: any) => {
          this.products = data.products;
          this.isEligible = data.isEligible;
        });
      }
    });
  }


  loadMarketWatchDashboard() {
    if (this.isEligible) {
      const link = this.router.serializeUrl(this.router.createUrlTree(['dashboard']));
      window.open(`${window.location.protocol}//${window.location.host}${link}`, '_blank');
    }
  }

  loadDocumentDetails(item: any) {
    if (item.isEligible) {
      var presignedUrlRequest = {
        ticket: this.cookieService.get('ticket'),
        productId: item.id,
        productName: item.name == 'MarketWatch Expanded Reports' ? 'ExpandedReports' : 'StandardReports',
        category: '',
        cstId: localStorage.getItem('cstid'),
        userId: localStorage.getItem('userid')

      };

      this.loblistService.getDocumentDetails(this.appConfig.marketwatchApiUrl, presignedUrlRequest).subscribe((data: any) => {
        this.s3FileDetails = data;
        var filesdata = {
          DownloadUrl: data.downloadUrl,
          FileName: data.fileName,
          title: "Download the " + item.name
        }
        if (data.fileName != null && data.fileName != '') {
          localStorage.setItem('s3fileDetails', null);
          localStorage.setItem('s3fileDetails', JSON.stringify(filesdata));
          const link = this.router.serializeUrl(this.router.createUrlTree(['download']));
          window.open(`${window.location.protocol}//${window.location.host}${link}`, '_blank');
        }
      });
    }
  }

}
