import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthConfig, OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { Router, Event, NavigationEnd } from '@angular/router';
import { EntitlementService } from './shared/service/entitlement.service';
import { HTTP_STATUS_CODE, PRIMARY_HOST_DOMAIN } from './shared/constants/app.constants';
import { ProfileSelectionService } from './shared/service/profile-selection.service';
import { switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { LobListService } from './components/lob-list/lob-list.service';

@Component({
  selector: 'marketwatch-app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {

    title: string = "MarketWatch";
    authConfig: AuthConfig = {};
    errorMessage: string = '';
    isAuthenticated: boolean = false;
    surveyUrl: string;
    deepLinkURL: string = '';

    constructor(
      private appService: AppService,
      private oauthService: OAuthService,
      private router: Router,
      private cookieService: CookieService,
      private entitlementService: EntitlementService,
      private profileSelectionService: ProfileSelectionService,
      private loblistService: LobListService
      ) {
          this.appService.getConfigStatus().subscribe(
              _configStatus => {
                if(_configStatus) {
                  let _appConfig = this.appService.getGlobalConfig();
                  this.authConfig.issuer = _appConfig.identityUrl;
                  this.authConfig.redirectUri = this.getRedirectUrl(_appConfig.redirectUrl);
                  this.authConfig.clientId = _appConfig.clientId;
                  this.authConfig.scope = 'openid';
                  this.authConfig.logoutUrl = _appConfig.identityUrl + '/connect/endsession';
                  this.surveyUrl = _appConfig.surveyUrl;
                  this.configureWithNewConfigApi();
                  this.entitlementService.getLoginStatus().subscribe(
                    status => {
                      this.isAuthenticated = status;
                    }
                  )
                }
              },
              error => this.errorMessage = <any>error
          );
    }

    private configureWithNewConfigApi() {
      this.oauthService.configure(this.authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.loadDiscoveryDocumentAndTryLogin();
    }

    getRedirectUrl(redirectUrl: string): string {
      const regexUrl = new RegExp(redirectUrl);
      if (regexUrl.test(window.location.href)) {
        return regexUrl.exec(window.location.href)[0].replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
      }
      return window.location.href.replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
   
    }

    ngOnInit(): void {
      this.router.events.subscribe((routerEvent: Event) => {
        this.checkRouterEvent(routerEvent);
      });
      
      this.deepLinkURL = window.location.href;
     }

     createDeepLinkUrl() { 
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
  
      // Remove the redirectedFromMenu query parameter
      params.delete('redirectedFromMenu');
  
      // Update the URL with the modified query parameters
      url.search = params.toString();
      this.deepLinkURL = url.toString();
    }

    checkRouterEvent(routerEvent: Event): void {
      if (routerEvent instanceof NavigationEnd) {
        if(this.cookieService.get('ticket')) {
          if(this.oauthService.getIdToken()){
            this.entitlementService.setLoginStatus(true);
            this.appService.getConfigStatus().subscribe(
              configStatus => {
                if (configStatus) {
              this.profileSelectionService.setProductContextCookie();
              this.profileSelectionService.hasCompanyAccess().pipe(
                switchMap(hasAccess => {
                  if (hasAccess === false) {
                    this.createDeepLinkUrl();
                    this.router.navigate(['/unauthorized']);
                    return of(false); // If the user doesn't have company access, return an observable that completes immediately
                  }
                  return this.profileSelectionService.includeIdealUX();
                }),
                switchMap((hasCompanyAccess) => {
                  if (hasCompanyAccess === false) {
                    this.router.navigate(['/unauthorized']);
                    return of(false);
                  }
                  const userId = localStorage.getItem("userid");
                  const custId = localStorage.getItem("cstid");
                  let _appConfig = this.appService.getGlobalConfig();

                  if(userId === null || custId === null) { 
                    this.router.navigate(['/unauthorized']);
                    return of(false);
                  }

                  forkJoin(
                  [
                    this.entitlementService.getProductEligibility(_appConfig.marketwatchApiUrl,userId,custId),
                    this.loblistService.getProducts(_appConfig.marketwatchApiUrl,userId,custId)
                  ])
                  .subscribe((results) => {
                    let isEligible = results[0].isEligible;
                    let products = results[1];

                    this.entitlementService
                      .setEligibility({
                        products: products, 
                        isEligible: isEligible
                      });

                    if(isEligible || products.filter(s => s.isEligible === true).length > 0) {
                      if (location.href.indexOf("unauthorized") > 0) {
                        this.router.navigate(['/']);
                      }
                    }
                    else {
                      this.router.navigate(['/unauthorized']);
                    }
                    return of(true);
                  }, (error) => {
                    if (error.status === HTTP_STATUS_CODE.NOT_FOUND ||
                      error.status === HTTP_STATUS_CODE.BAD_REQUEST) {
                      this.router.navigate(['/unauthorized']);
                    }
                    return of(false);
                  });
                })
              )
                .subscribe(
                  result => {
                    this.createDeepLinkUrl();
                  },
                  error => {
                    this.errorMessage = error;
                  }
                );
              }
          })
          }
          else if(!new RegExp('#id_token=.+').test(location.hash)) {
            this.entitlementService.setLoginStatus(false)
            this.oauthService.initImplicitFlow();
          }
        } else {
          if(this.oauthService.getIdToken()) {
            this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
            this.entitlementService.setLoginStatus(false);
            this.oauthService.logoutUrl = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).iss + '/connect/endsession'; //'https://idt.iso.com/identity/connect/endsession'
            this.oauthService.logOut();
          } else {
            this.oauthService.initImplicitFlow();
          }
        }
      }
    }
}