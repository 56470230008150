import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IEligibleProduct, PrdEligible } from '../../../models/eligibleproduct.model';
import { LOCAL_STORAGE_ELIGIBLE_PRODUCTS } from '../constants/app.constants';

@Injectable({
    providedIn: 'root', //root level, angular creates a single shared instance of this service
})

export class EntitlementService {

    private isAuthenticated: BehaviorSubject<boolean>

    private eligibilitySubject = new BehaviorSubject<any[]>([]);
    eligibility$ = this.eligibilitySubject.asObservable();

    constructor(private http: HttpClient) {
        this.isAuthenticated = new BehaviorSubject(false)
        window.addEventListener('storage', this.onStorageChange.bind(this));
    }

    private onStorageChange(event: StorageEvent) {
        if (event.key === LOCAL_STORAGE_ELIGIBLE_PRODUCTS && event.newValue) {
            const updatedData = JSON.parse(event.newValue);
            this.eligibilitySubject.next(updatedData);
            window.location.href = "/";
        }
    }

    setEligibility(data: any) {
        this.eligibilitySubject.next(data);
        localStorage.setItem(LOCAL_STORAGE_ELIGIBLE_PRODUCTS, JSON.stringify(data));
    }

    loadEligibility() {
        const data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_ELIGIBLE_PRODUCTS) || '{}');
        this.eligibilitySubject.next(data);
    }

    getLoginStatus(): Observable<boolean> {
        return this.isAuthenticated.asObservable()
    }

    setLoginStatus(status: boolean): void {
        this.isAuthenticated.next(status)
    }

    getUserEligibleISOProducts(token: string, isoUserSvcUrl: string): Observable<IEligibleProduct> {
        return this.http.get<IEligibleProduct>(`${isoUserSvcUrl}/getLinksForProductsMyCompanyIsEligibleFor/${token}`).pipe(
            catchError(this.handleError)
        );
    }

    getUserRegisteredISOProducts(token: string, isoUserSvcUrl: string): Observable<IEligibleProduct> {
        return this.http.get<IEligibleProduct>(`${isoUserSvcUrl}/getLinksToProductsIAmRegisteredFor/${token}`).pipe(
            catchError(this.handleError)
        );
    }

    getTableauToken(apiUrl: string, userId: string, companyId: string): Observable<string> {
        return this.http.get(`${apiUrl}/tableau/authenticate?userId=${userId}&companyId=${companyId}&time=${new Date().getTime()}`, { responseType: 'text' }).pipe(
            catchError(this.handleError)
        );
    }

    getCompanyToken(apiUrl: string, userId: string, companyId: string): Observable<string> {
        return this.http.get(`${apiUrl}/tableau/encrypt?userId=${userId}&companyId=${companyId}&time=${new Date().getTime()}`, { responseType: 'text' }).pipe(
            catchError(this.handleError)
        );
    }

    getProductEligibility(apiUrl: string, userId: string, cstId: string): Observable<PrdEligible> {
        return this.http.get<PrdEligible>(`${apiUrl}/api/product/eligible?userId=${userId}&companyId=${cstId}`);
    }

    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}