export const USER_PROFILE_FILTER_ARGS: string[] = ['ADMIN', 'C_PASSWORD', 'PROFILE', 'PRODUCT_NEWS']
export const ISO_EDUCATION_FILTER_ARGS: string[] = ['EDUCATION']
export const ISO_FOOTER_FILTER_ARGS: string[] = ['ISO_HOME', 'TERMS', 'POLICY', 'CONTACT']
export const PRIMARY_HOST_DOMAIN: string = '.verisk.com'
export const LOCAL_STORAGE_ELIGIBLE_PRODUCTS: string = 'products';

export const LABEL_LIST: any = { 
    menu: "menu",
    person: "person",
    linkTitle: "MarketWatch",
    feedback: "Feedback",
    productTitle: "MarketWatch"
}; 
export const HTTP_STATUS_CODE = {
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
};